import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import { Button, Modal, DatePicker, notification, message } from 'antd';
import Header from '../Header';

const AddDeliveryMan = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [mobileno, setMobileno] = useState('');
    const [deliverCode, setDeliverCode] = useState('');
    const [age, setAge] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchDeliverCode = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('http://localhost:8080/api/delivery/generateCode', {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.text();
                if (response.status === 200) {
                    setDeliverCode(data);
                } else {
                    console.error('Failed to fetch delivery code');
                }
            } catch (error) {
                console.error('Error fetching delivery code:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchDeliverCode();
    }, []);
    const validateForm = () => {
        let valid = true;
        let errors = {};
        const mobileRegex = /^\d{10}$/;
        if (!mobileRegex.test(mobileno)) {
            errors.mobileno = "Mobile number must be exactly 10 digits.";
            valid = false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            errors.email = "Please enter a valid email address.";
            valid = false;
        }
        setErrors(errors);
        return valid;
    };
    const onFinish = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        const token = localStorage.getItem('token');

        try {
            setLoading(true);
            const response = await fetch(
                'http://localhost:8080/api/delivery/savedelivery',
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: localStorage.getItem('id'),
                        name: name,
                        deliverCode: deliverCode,
                        age: age,
                        email: email,
                        address: address,
                        mobileno: mobileno,
                    }),
                }
            );
            const data = await response.text();
            if (response.status === 401 || response.status === 404) {
                navigate('/login');
                return;
            }
            if (response.status == 200) {
                message.success('Deliver added successfully');
                navigate('/deliverman-list');
            } else {
                message.error(data.description);
            }
        } catch (error) {
            console.error('Error posting data:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="wrapper">
            <SideBar />

            <div className="ec-page-wrapper">
                <Header />
                <div className="ec-content-wrapper">
                    <div className="content" >
                        <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                            <h1>Deliver Man</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <div className="ec-cat-list card card-default mb-24px">
                                    <div className="card-body">
                                        <div className="ec-cat-form">
                                            <h4>Add</h4>
                                            <form
                                                onSubmit={onFinish}
                                            >
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Name</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Deliver Code</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="text" value={deliverCode} onChange={(e) => setDeliverCode(e.target.value)} autoComplete="off" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Age</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="number" value={age} onChange={(e) => setAge(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Email</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                                                        {errors.email && <small className="text-danger">{errors.email}</small>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Mobile</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="number" value={mobileno} onChange={(e) => setMobileno(e.target.value)} autoComplete="off" />
                                                        {errors.mobileno && <small className="text-danger">{errors.mobileno}</small>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Address</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="text" value={address} onChange={(e) => setAddress(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button name="submit" type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddDeliveryMan;
