import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "../../style.scss";
import "../../riot_admin.scss";
import SideBar from "../../vendor/sidebar/index";
import { Input,Form, Upload, Button, Modal ,notification} from 'antd';
import VendorHeader from "../../../src/vendor/header";
import { UploadOutlined } from '@ant-design/icons';
import EditSocialMediaIconsforSeller from "../../../src/vendor/socialmedia/editSocialMediaiCon";

const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];
  

  const SocialMediaIconsforSeller = () => {
	const token = sessionStorage.getItem('tokenforseller');
    const navigate = useNavigate();
	const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	const [categoryList, setCategoriesList] = useState([]);

	const [clientId, setClientId] = useState(null);
    //variables
    const [title, setTitle] =useState('');
    const [description, setDescription] =useState('');
    const [file, setUploadfiles] =useState([]); 
    const [fileList, setFileList] = useState([]); // To store uploaded files
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [iconimage, setIconimage] = useState('');
    
    const [iconname, setIconName] = useState('');
	const [showDeleteModal, setShowDeleteModal] = useState(false);
const [selectedCommonInfoForDeletion, setSelectedCommonInfoForDeletion] = useState(null);
    //Dropdown
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		function getAllFileIds(category) {
			// Check if the 'files' property exists in the category object
			if (category && category.files && Array.isArray(category.files)) {
				// Map over the array and extract the fileId property
				return category.files.map(file => file.fileid);
			} else {
				// Handle the case where 'files' property is missing or not an array
				console.error("Invalid category object or missing 'files' property.");
				return [];
			}
		}
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	 
	  const handleFileUpload = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		const formData = new FormData();
		formData.append("file", file);
	  
		try {
		  const response = await axios.post(
			"http://68.178.169.226:12080/api/v1/sellersocialmedia/file",
			formData,
			{
			  onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
				  (progressEvent.loaded * 100) / progressEvent.total
				);
				onProgress({ percent: percentCompleted });
			  },
			  headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'multipart/form-data',
			  },
			}
		  );
	  
		  if (response.status === 401 || response.status === 404 || !token) {
			navigate('/AdminLogin');
			return;
		  }
	  
		  if (response.status === 200) {
			const uploadedIconUrl = response.data.organiserId; // Assuming response.data contains the URL of the uploaded icon
			setIconimage(uploadedIconUrl); // Set the iconimage state with the uploaded icon URL as a string
			onSuccess("File uploaded");
		  } else {
			onError("File upload failed");
		  }
		} catch (error) {
		  onError("File upload failed");
		}
	  };
      const resetFormFields = async () => {
        setTitle('');
        setDescription('');
        setIconimage('');
        setFileList([]);
      }
	  
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'http://68.178.169.226:12080/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
            const responseData = await response.json();
            setClientId(responseData.id); // Store the client ID in state
          } else {
            console.error('Error fetching client details');
          }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
      const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = sessionStorage.getItem('tokenforseller');
		try {
		  setLoading(true);
		  // TODO: Replace with actual API call
		  const response = await fetch("http://68.178.169.226:12080/api/v1/sellersocialmedia/add", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				sellerid:clientId,
			  title,
			  description, 
              iconimage: iconimage,
              iconname,
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) { 
			// Redirect to the login page for 401 or 404 errors
			// navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
            
  notification.success({
    message: data.description,
    duration: 3, // Duration in seconds
  });
          fetchData();
          resetFormFields();
			const token = data.token;
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			setLoading(false);
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  };

      //Get API

      useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("http://68.178.169.226:12080/api/v1/sellersocialmedia/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setCategoriesList(response.data);
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

      //Delete API

	  const handleDeleteCategory = (socialmediaId) => {
		const selectedCommonInfo = data.find((cinfo) => cinfo.socialmediaid === socialmediaId);
		setSelectedCommonInfoForDeletion(selectedCommonInfo);
		setShowDeleteModal(true);
	  };
	  const handleDeleteCategoryConfirmed = async () => {
		try {
		  const response = await axios.post(
			`http://68.178.169.226:12080/api/v1/sellersocialmedia/delete`,
			{
                socialmediaid: selectedCommonInfoForDeletion.socialmediaid,
			},
			{
			  headers: {
				Authorization: 'Bearer ' + sessionStorage.getItem('tokenforseller'),
				'Content-Type': 'application/json',
			  },
			}
		  );
	  
  notification.success({
    message: response.data.description,
    duration: 3, // Duration in seconds
  });
          fetchData();
		  if (response.status === 200) {
			fetchData(); // Refresh the page or update the common info list
		  } else {
			console.error('Failed to delete common info');
		  }
		} catch (error) {
		  console.error('Error deleting common info:', error);
		} finally {
		  // Clear the selected common info and hide the modal
		  setSelectedCommonInfoForDeletion(null);
		  setShowDeleteModal(false);
		}
	  };
			


	const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  const handleIconChange = (event) => {
        setIconName(event.target.value);
        console.log("Selected icon:", event.target.value);
      };
	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		<div className="ec-page-wrapper">
			{/* <!-- Header --> */}
			<VendorHeader/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Social Media Icons</h1>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Add Social Media Icon</h4>

										<form
										onSubmit={onFinish} encType="multipart/form-data"
										>

											<div className="form-group row">
												<label for="text" className="col-12 col-form-label">Title</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div className="form-group row">
												<label className="col-12 col-form-label">URL</label> 
												<div className="col-12">
													<input id="sortdescription" name="sortdescription"  className="form-control" value={description} onChange={(e) => setDescription(e.target.value)}></input>
												</div>
											</div> 
                                            <div className="col-md-12">
                                            <label className="form-label" name="file">Icons</label>
                                            <select
                                            className='form-select'
                                            id="iconSelect"
                                            value={iconname}
                                            onChange={handleIconChange}
                                            >
                                                <option value="r-icon-fb">Facebook</option>
        <option value="r-icon-insta">Instagram</option>
        <option value="r-icon-twiter">Twitter</option>
        <option value="r-icon-youtube">Youtube</option>
		<option value="r-icon-whatsapp">WhatsApp</option>
		<option value="r-icon-linkedIn">LinkedIn</option>
                                            </select>
                                            </div>
											{/* <div className="col-md-12">
													<label className="form-label" name="file">Upload Icons</label>
													<Form.Item
															name="file"
														>
														<Upload
                                                        customRequest={handleFileUpload} 
                                                        fileList={fileList}
															onChange={({ fileList }) => setFileList(fileList)}>
															<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
														</Upload>
													</Form.Item> 
												</div> */}

											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>Title</th>
													<th>Description</th>
													{/* <th>Icons</th> */}
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((cinfo) => (
                          <tr key={cinfo.socialmediaid}>
                            <td>{cinfo.title}</td>
                            <td>{cinfo.description}</td>
                            {/* <td>{cinfo.iconname}</td> */}
                            {/* <td>
        {cinfo.iconimage ? (
          <img src={cinfo.iconimage} alt="Icon" width="100" height="100" />
        ) : (
          "No image available"
        )}
      </td> */}
                            <td>
							<div className="btn-group">
   <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault();
        handleEditCategory(cinfo);
      }}
    >
      Edit
    </a> 
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); 
        handleDeleteCategory(cinfo.socialmediaid);
      }}
    >
      Delete
    </a> 
  </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				 {selectedCategory && (
  <EditSocialMediaIconsforSeller
  show={showEditModal}
  onHide={handleCloseEditModal}
  categoryList={categoryList}
  category={selectedCategory}
  handleFileUpload={handleFileUpload}
  fetchData={fetchData}
/>
)}	 
<Modal
open={showDeleteModal}
title="Confirm Deletion"
onCancel={() => setShowDeleteModal(false)}
onOk={handleDeleteCategoryConfirmed}
okText="Delete"
>
<p>Are you sure you want to delete this Icon?</p>
</Modal>
			</div> 

</div>
	</div>
  );
}

export default SocialMediaIconsforSeller;
