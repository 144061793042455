import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import _ from 'lodash';

const EditDeliveryMan = ({ deliver, onClose }) => {
    const [form] = Form.useForm();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (deliver) {
            form.setFieldsValue(deliver);
        }
    }, [deliver, form]);

    const handleDeliverUpdate = async (values) => {
        const clonedFormData = _.cloneDeep({ ...values, deliveryManId: deliver.deliverymanid });
        try {
            const response = await axios.post(`http://localhost:8080/api/delivery/updatedelivery`, clonedFormData, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });
            if (response.status === 200) {
                onClose();
                message.success('Deliver updated successfully');
            } else {
                console.error('Deliver update failed');
            }
        } catch (error) {
            console.error('Error updating Deliver:', error);
        }
    };

    return (
        <Form
            form={form}
            name="Delivery Man Form"
            initialValues={deliver}
            onFinish={handleDeliverUpdate}
            layout='vertical'
        >
            <Form.Item
                name="name"
                label="Name"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the name!' }]}
            >
                <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
                name="deliverCode"
                label="Delivery Code"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the delivery code!' }]}
            >
                <Input placeholder="Delivery Code" />
            </Form.Item>
            <Form.Item
                name="age"
                label="Age"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the age!' }]}
            >
                <Input type="number" placeholder="Age" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the email!' }]}
            >
                <Input type="email" placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="address"
                label="Address"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the address!' }]}
            >
                <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
                name="mobileno"
                label="Mobile No"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the mobile number!' }]}
            >
                <Input type="number" placeholder="Mobile No" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button onClick={onClose} style={{ marginLeft: '10px' }}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default EditDeliveryMan;
