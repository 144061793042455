import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import { Button, Modal, DatePicker, notification, message, InputNumber, Checkbox, Table } from 'antd';
import Header from '../Header';

const AssignDeliveryManAdd = () => {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [date, setDate] = useState('');
    const [deliver, setDeliver] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState({ empid: '', empName: '' });
    const [deliveryProducts, setDeliveryProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [bulkSelected, setBulkSelected] = useState(false);
    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };
    useEffect(() => {
        if (!token) {
            navigate('/AdminLogin');
            return;
        }
        setDate(formatDate(new Date()));
        fetchDelivers();
        fetchProducts();
        fetchCustomers();
    }, []);
    const fetchCustomers = async () => {
        try {
            const response = await axios.get('http://localhost:8080/api/customermilk/milkcustomer', {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 401 || response.status === 404) {
                navigate('/AdminLogin');
                return;
            }
            setCustomers(response.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };
    const fetchDelivers = async () => {
        try {
            const response = await axios.get('http://localhost:8080/api/delivery/findall', {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 401 || response.status === 404) {
                navigate('/AdminLogin');
                return;
            }
            setDeliver(response.data);
        } catch (error) {
            console.error('Error fetching delivery persons:', error);
        }
    };
    const fetchProducts = async () => {
        try {
            const response = await axios.get('http://localhost:8080/api/milkproducts/getAllProducts', {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 401 || response.status === 404) {
                navigate('/AdminLogin');
                return;
            }
            setProduct(response.data);
            const initialProducts = response.data.map(p => ({
                pid: p.productid,
                pname: p.pname,
                qnty: 0
            }));
            setDeliveryProducts(initialProducts);

        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleQuantityChange = (pid, value) => {
        const updatedProducts = deliveryProducts.map(item =>
            item.pid === pid ? { ...item, qnty: value } : item
        );
        setDeliveryProducts(updatedProducts);
    };

    const onFinish = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const assignDeliverRequest = {
                adminid: localStorage.getItem('id'),
                empid: selectedEmployee.empid,
                date: date,
                empName: selectedEmployee.empName,
                clientid: selectedRowKeys,
                deliveryProducts: deliveryProducts.filter(p => p.qnty > 0),
            };

            const response = await fetch(
                'http://localhost:8080/api/assign-delivery/createAssignDeliverPerson',
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(assignDeliverRequest),
                }
            );
            const data = await response.json();
            if (response.status === 401 || response.status === 404) {
                navigate('/login');
                return;
            }
            if (response.status === 201) {
                message.success('Deliveryman assignment added successfully');
                navigate('/assigndeliverman-list');
            } else {
                message.error(data.description || 'Error adding delivery assignment');
            }
        } catch (error) {
            console.error('Error posting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEmployeeSelect = (e) => {
        const selectedId = e.target.value;
        const employee = deliver.find(d => d.deliverymanid === parseInt(selectedId));
        if (employee) {
            setSelectedEmployee({
                empid: employee.deliverymanid,
                empName: employee.name,
            });
        }
    };
    const handleSelectCustomer = () => {
        fetchCustomers();
        setIsModalOpen(true);
    };
    const handleSelectSingleCustomer = (clientid) => {
        if (selectedRowKeys.includes(clientid)) {
            setSelectedRowKeys(selectedRowKeys.filter((key) => key !== clientid));
        } else {
            setSelectedRowKeys([...selectedRowKeys, clientid]);
        }
    };
    const handleBulkSelect = (e) => {
        setBulkSelected(e.target.checked);
        if (e.target.checked) {
            const allCustomerIds = customers.map((customer) => customer.clientid);
            setSelectedRowKeys(allCustomerIds);
        } else {
            setSelectedRowKeys([]);
        }
    };
    const handleSubmit = () => {
        const selectedCustomerNames = customers
            .filter(customer => selectedRowKeys.includes(customer.clientid))
            .map(customer => `${customer.firstname} - ${customer.milkQuantity}`)
            .join(', ');
        setSelectedCustomer(selectedCustomerNames);
        setIsModalOpen(false);
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Milk Quantity',
            dataIndex: 'milkQuantity',
            key: 'milkQuantity',
        },
        {
            title: 'Milk Item',
            dataIndex: 'milkitem',
            key: 'milkitem',
        },
        {
            title: 'Deliver Day',
            dataIndex: 'deliverDay',
            key: 'deliverDay',
        },
        {
            title: 'Select',
            key: 'select',
            render: (text, record) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.clientid)}
                    onChange={() => handleSelectSingleCustomer(record.clientid)}
                />
            ),
        },
    ];
    return (
        <div className="wrapper">
            <SideBar />
            <div className="ec-page-wrapper">
                <Header />
                <div className="ec-content-wrapper">
                    <div className="content">
                        <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                            <h1>Assign Deliverman</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <div className="ec-cat-list card card-default mb-24px">
                                    <div className="card-body">
                                        <div className="ec-cat-form">
                                            <h4>Add</h4>
                                            <form onSubmit={onFinish}>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Select Employee</label>
                                                    <div className="col-12">
                                                        <select
                                                            className="form-control"
                                                            value={selectedEmployee.empid}
                                                            onChange={handleEmployeeSelect}
                                                        >
                                                            <option value="">Select Employee</option>
                                                            {deliver.map(d => (
                                                                <option key={d.deliverymanid} value={d.deliverymanid}>
                                                                    {d.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Date</label>
                                                    <div className="col-12">
                                                        <input
                                                            id="text"
                                                            name="text"
                                                            className="form-control here slug-title"
                                                            type="text"
                                                            value={date}
                                                            onChange={(e) => setDate(e.target.value)}
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Select Customer</label>
                                                    <div className="col-12">
                                                        <input
                                                            id="text"
                                                            name="text"
                                                            className="form-control here slug-title"
                                                            type="text"
                                                            value={selectedCustomer}
                                                            onClick={handleSelectCustomer}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <Modal
                                                        title={
                                                            <div>
                                                                <Checkbox
                                                                    checked={bulkSelected}
                                                                    onChange={handleBulkSelect}
                                                                >
                                                                    Select All
                                                                </Checkbox>
                                                            </div>
                                                        }
                                                        open={isModalOpen}
                                                        onCancel={() => setIsModalOpen(false)}
                                                        footer={[
                                                            <Button key="submit" type="primary" onClick={handleSubmit}>
                                                                Submit
                                                            </Button>,
                                                            <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                                                                Cancel
                                                            </Button>,
                                                        ]}
                                                        width={800}
                                                    >
                                                        <Table
                                                            dataSource={customers}
                                                            columns={columns}
                                                            rowKey="clientid"
                                                            pagination={false}
                                                            rowSelection={{
                                                                selectedRowKeys,
                                                                onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
                                                            }}
                                                        />
                                                    </Modal>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Delivery Products</label>
                                                    <div className="col-12">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>Product Name</th>
                                                                    <th>Quantity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {product.map((p, index) => (
                                                                    <tr key={p.productid}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{p.pname}</td>
                                                                        <td>
                                                                            <InputNumber
                                                                                min={1}
                                                                                value={deliveryProducts.find(item => item.pid === p.productid)?.qnty || 0}
                                                                                onChange={(value) => handleQuantityChange(p.productid, value)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <button name="submit" type="submit" className="btn btn-primary">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignDeliveryManAdd;
