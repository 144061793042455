import React, { useState, useEffect } from "react";
import "../style.scss";
import "../riot_admin.scss";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "antd";
import axios from "axios";
import { Select, DatePicker, Checkbox, Row, Col } from "antd";
import Stripe from "../../src/stripe/stripe";
import LoginHeader from "../componant/LoginHeader";

const Stage3 = () => {
  const token = localStorage.getItem("tokenforseller");
  const navigate = useNavigate();
  const [planname, setPan] = useState("");
  const [noofcategories, setAadhar] = useState("");
  const [noofproducts, setbusinesspan] = useState("");
  const [currentnoofproducts, setAccdetails] = useState("");
  const [termsandcondition, setAccname] = useState("");
  const [agreedprocesstopayment, setAccnumber] = useState("");
  const [payment, setifsccode] = useState("");
  const [paymentdetails, setBranch] = useState("");
  const [data, setData] = useState("");
  const [clientId, setClientId] = useState(null);
  const [planOptions, setPlanOptions] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [categoryList, setCategoriesList] = useState([]);
  const [editable, setEditable] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPlanNoOfCategories, setSelectedPlanNoOfCategories] =
    useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/login/getuserdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(
                "tokenforseller"
              )}`,
            },
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setClientId(responseData.id); // Store the client ID in state
        } else {
          console.error("Error fetching client details");
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/sellerplan/get",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          setPlanOptions(responseData); // Update this line
        } else {
          console.error("Error fetching client details");
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchPlanDetails();
  }, []);
  useEffect(() => {
    const fetchCategoryNames = async () => {
      try {
        const response = await axios.put(
          "http://68.178.169.226:12080/api/v1/category/get",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 401 || response.status === 404) {
          // Redirect to the login page for 401 or 404 errors
          // navigate('/login');
          return; // Exit early to prevent further logic execution
        }
        setCategoriesList(response.data);
      } catch (error) {
        console.error("Error fetching names:", error);
      }
    };

    fetchCategoryNames();
  }, []);

  const onFinish = async (e) => {
    console.log("Submit button clicked");
    e.preventDefault(); // Prevent the default form submission behavior

    // Check if termsandcondition and agreedprocesstopayment are true
    if (!termsandcondition || !agreedprocesstopayment) {
      console.error(
        "Please agree to terms and conditions and payment process before submitting."
      );
      // You can also show an error message to the user
      return;
    }

    try {
      // Find the selected plan by sellerplanid from the stored planOptions
      const selectedPlan = planOptions.find(
        (plan) => plan.sellerplanid === selectedPlanId
      );

      // TODO: Replace with actual API call
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/seller/edit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + token,
          },
          body: JSON.stringify({
            sellerid: clientId,
            planname: selectedPlan ? selectedPlan.planname : "", // Use the selected plan name
            noofcategories: selectedPlan ? selectedPlan.noofcategories : "",
            noofproducts: selectedPlan ? selectedPlan.noofproducts : "",
            freeshipping: selectedPlan ? selectedPlan.freeshipping : "",
            termsandcondition: "true",
            agreedprocesstopayment: "true",
            payment: "true",
            repayment: false,
            paymentdetails: "",
            sellerplanid: selectedPlanId,
            categoryid: selectedCategories,
          }),
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return; // Exit early to prevent further logic execution
      }

      if (response.ok) {
        const data = await response.json();
        console.log("POST request successful", data);
        if (data && data.length > 0) {
          setData(data);
        } else {
          setData([]); // If no data is received, set an empty array
        }
        // window.location.reload(); // Refresh the page
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleNameSelect = (sellerplanid) => {
    setSelectedPlanId(sellerplanid);

    // Find the selected plan by sellerplanid from the stored planOptions
    const selectedPlan = planOptions.find(
      (plan) => plan.sellerplanid === sellerplanid
    );
    setSelectedPlanNoOfCategories(
      selectedPlan ? selectedPlan.noofcategories : 0
    );

    // Reset selected categories when a new plan is selected
    setSelectedCategories([]);
  };

  const handleNameSelectforCategory = (value) => {
    // Check if the selected plan has unlimited categories
    const isUnlimitedCategories = selectedPlanNoOfCategories === "unlimited";

    // Allow any number of categories if it's unlimited, otherwise enforce the limit
    if (isUnlimitedCategories || value.length <= selectedPlanNoOfCategories) {
      setSelectedCategories(value);
    } else {
      // Display a message or handle the case where the limit is exceeded
      console.error("Exceeded the limit of selected categories");
    }
  };

  const handlePayment = async () => {
    try {
      // Fetch seller plans
      const planResponse = await fetch(
        "http://68.178.169.226:12080/api/v1/sellerplan/get",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!planResponse.ok) {
        console.error("Error fetching seller plans");
        return;
      }

      const planData = await planResponse.json();

      // Find the selected plan by sellerplanid
      const selectedPlan = planData.find(
        (plan) => plan.sellerplanid === selectedPlanId
      );

      if (selectedPlan) {
        // Call handlePayment with the amount from the selected plan
        fetchPaymentData(selectedPlan.amount);

        // Disable the plan name dropdown after payment
        setEditable(false);
      }
    } catch (error) {
      console.error("Error fetching plans for payment:", error);
    }
  };
  const fetchPaymentData = async (
    amount,
    currency = "INR",
    paymentMethod = "card"
  ) => {
    try {
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/payment/payment",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tokenforseller")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: parseFloat(amount),
            currency: currency,
            paymentmethod: paymentMethod,
          }),
        }
      );

      if (response.ok) {
        const data = await response.text();
        setClientSecret(data);
      } else {
        alert(JSON.stringify(response));
      }
    } catch (error) {
      console.error("Error in payment:", error);
    }
  };
  const paymenttake = () => {
    const selectedPlan = planOptions.find(
      (plan) => plan.sellerplanid === selectedPlanId
    );
    const paytake = JSON.stringify({
      sellerid: clientId,
      planname: selectedPlan ? selectedPlan.planname : "", // Use the selected plan name
      noofcategories: selectedPlan ? selectedPlan.noofcategories : "",
      noofproducts: selectedPlan ? selectedPlan.noofproducts : "",
      freeshipping: selectedPlan ? selectedPlan.freeshipping : "",
      termsandcondition: "true",
      agreedprocesstopayment: "true",
      payment: "true",
      paymentdetails,
      sellerplanid: selectedPlanId,
      categoryid: selectedCategories,
    });
    const paymentforSellerEdit = sessionStorage.setItem(
      "paymentforSellerEdit",
      paytake
    );

    const fetchPaymentData = async (
      amount,
      currency = "INR",
      paymentMethod = "card"
    ) => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/payment/payment",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(
                "tokenforseller"
              )}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: parseFloat(amount),
              currency: currency,
              paymentmethod: paymentMethod,
            }),
          }
        );

        if (response.ok) {
          const data = await response.text();
          setClientSecret(data);
          setModalVisible(true);
        } else {
          alert(JSON.stringify(response));
        }
      } catch (error) {
        console.error("Error in payment:", error);
      }
    };
    fetchPaymentData(selectedPlan.amount);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/AdminLogin");
  };
  return (
    <div>
      <LoginHeader />
      <div className="registration-container">
        <div className="container text-center pb-4">
          <p className="fs-4">
            {" "}
            Thank you for choosing Shopeasey as your platform for selling. We
            appreciate your interest in joining our community of sellers. During
            the registration process, we are carefully reviewing your
            information to ensure the highest quality experience for both
            sellers and buyers.
          </p>
        </div>
        <div className="registration-process  container d-flex justify-content-center">
          <div className="registration-process-done">
            <span> 1 </span> Personal Details
          </div>
          <div className="registration-process-done">
            <span> 2 </span> Business Details
          </div>
          <div className="registration-process-active">
            <span> 3 </span> Plan & Payment
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="registration">
            <div className="container-fluid text-center seller-plan">
              <Row gutter={16} className="row-plans">
                {planOptions.map((plan) => (
                  <Col key={plan.sellerplanid}>
                    <div className="plan-card">
                      <h3>{plan.planname}</h3>
                      <p>
                        {plan.amount} per month (no.of months - {plan.noofmonth}
                        )
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <div className="plan-box d-flex justify-content-center">
              <form>
                <div className="form-group row">
                  <label htmlFor="text" className="col-12 col-form-label">
                    Plan Name
                  </label>
                  <div className="col-md-12">
                    <Select
                      style={{ width: "60%", border: "2px solid #8f8f8f" }}
                      onChange={(value) => handleNameSelect(value)} // Pass a function reference
                    >
                      {planOptions.map((name) => (
                        <Select.Option
                          key={name.sellerplanid}
                          value={name.sellerplanid}
                        >
                          {name.planname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="text" className="col-12 col-form-label">
                    Categories (No. of Categories Allowed:{" "}
                    {selectedPlanNoOfCategories})
                  </label>
                  <div className="col-12">
                    <Select
                      style={{ width: "60%", border: "2px solid #8f8f8f" }}
                      mode="multiple"
                      onChange={handleNameSelectforCategory}
                      value={selectedCategories}
                    >
                      {categoryList.map((name) => (
                        <Select.Option
                          key={name.categoryid}
                          value={name.categoryid}
                        >
                          {name.categoryname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="form-group row">
                  <Link
                    to="https://demo.shopeasey.com/Termsconditionforseller"
                    target="_blank"
                  >
                    <label className="col-12 col-form-label">
                      Terms and Condition
                    </label>
                  </Link>
                  <div className="col-12">
                    <Row gutter={16}>
                      <Col span={12}>
                        <Checkbox
                          value="yes"
                          required
                          onChange={(e) => setIsChecked(e.target.checked)}
                        >
                          Yes
                        </Checkbox>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="row">
                  <Row gutter={16}>
                    <Col span={24} className="justify-content-center">
                      <Button
                        type="primary"
                        onClick={paymenttake}
                        disabled={!isChecked}
                      >
                        Pay
                      </Button>
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
          </div>{" "}
        </div>{" "}
      </div>

      <Modal
        title="Payment Modal"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {clientSecret && (
          <Stripe
            style={{ width: "50%", alignSelf: "center" }}
            clientSecret={clientSecret}
            isSucceeded={(value) => {
              console.log(value);
              setIsSucceeded(value);
              setClientSecret(null);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Stage3;
