import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./style.scss";
import "./riot_admin.scss";
import SideBar from "./sidebar";
import { Input, Modal } from "antd";
import Header from "./Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditProductModal from "./EditProduct";
import {
  Select,
  Form,
  Upload,
  Button,
  DatePicker,
  ConfigProvider,
  notification,
} from "antd";
import {
  UploadOutlined,
  CloseCircleOutlined,
  ColumnWidthOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import ColorPaletteModal from "./colorPalaetteModal";
import ImgCrop from "antd-img-crop";

const ProductAdd = () => {
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleUnitSelect = (value) => {
    setUnit(value);
  };
  const [selectedProductTypeId, setSelectedProductTypeId] = useState("");
  const [grouptypedata, setGrouptypedata] = useState([]);
  const [groupapitypedata, setGroupapitypedata] = useState([]);
  const [categorydata, setcategoryData] = useState([]);
  const [fileList, setFileList] = useState([]); // To store uploaded files
  // const [uploadfile, setUploadfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productname, setProductname] = useState("");
  const [slug, setSlug] = useState("");
  const [sortdescription, setSortdescription] = useState("");
  const [fulldetails, setFulldetails] = useState("");
  const [color, setColor] = useState("");
  const [colorname, setColorname] = useState("");
  const [offerprice, setOfferprice] = useState("");
  const [offerstart, setOfferstart] = useState("");
  const [offerend, setOfferend] = useState("");
  const [productunit, setUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [sellingprice, setSellingprice] = useState("");
  const [mrpprice, setMrpprice] = useState("");
  const [pricesymbol, setPricesymbol] = useState("");
  const [storage, setStorage] = useState("");
  const [display, setDisplaysize] = useState("");
  const [os, setOS] = useState("");
  const [size, setSize] = useState([]);
  const [ram, setRam] = useState("");
  const [weight, setWeight] = useState("");
  const [data, setData] = useState([]);
  const [categoryList, setCategoriesList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState("");
  const [subcategoryList, setSubCategoriesList] = useState([]);
  const [subsubcategoryList, setSubSubCategoriesList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productposition, setProductposition] = useState("");
  const [youtube, setYoutube] = useState("");
  const [isOfferPriceValid, setIsOfferPriceValid] = useState(true);
  const [offerPriceErrorMessage, setOfferPriceErrorMessage] = useState("");
  const [producttags, setProducttags] = useState("");
  const [preview, setpreview] = useState("");
  const [isSellingPriceValid, setIsSellingPriceValid] = useState(true);
  const [sellingPriceErrorMessage, setSellingPriceErrorMessage] = useState("");
  const [isModalVisibleforagain, setIsModalVisibleforagain] = useState(false);
  const [capacity, setCapacity] = useState("");
  const [bookname, setBookname] = useState("");
  const [author, setAuthor] = useState("");
  const [type, setTypeac] = useState("");
  const [starrating, setStarrating] = useState("");
  const [displayunit, setdisplayunit] = useState("");
  const [capacityunit, setcapacityunit] = useState("");
  const [groupTypeFields, setGroupTypeFields] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [countryoforigin, setcountryorigin] = useState("");
  const [brandName, setBrandName] = useState("");
  const [material, setMaterial] = useState("");
  const [Agegroup, setAgegroup] = useState("");
  const [Gender, setGender] = useState("");
  const [packagecontent, setPackagecontent] = useState("");
  const [packageweight, setPackageweight] = useState("");
  const [packagingunit, setPackagingunit] = useState("");
  const [variations, setVariations] = useState({});
  const [stages, setStages] = useState(1);
  const [fragile, setFragile] = useState(false);
  const handleClick = () => {
    setStages((prevStages) => {
      const newStages = prevStages < 5 ? prevStages + 1 : prevStages;
      console.log(newStages);
      return newStages;
    });
  };
  const options = [
    { value: "bestseller", label: "Latest Product" },
    { value: "featuredproduct", label: "Flash Selling" },
    // Add more options here if needed
  ];
  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
  const [selectedColor, setSelectedColor] = useState("");

  const [dynamicformData, setDynamicformData] = useState({});
  const [dynamicformDatacount, setDynamicformDatacount] = useState(1);
  const handleSelectColor = (selectedHex) => {
    setSelectedColor(selectedHex);
  };
  const handleFileUpload = async (field,options, index) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/fileupload/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: "Bearer " + token, // Set the Authorization header with the token
            // 'Content-Type': 'application/json',
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        // Redirect to the login page for 401 or 404 errors or if the token is not present
        navigate("/AdminLogin");
        return; // Exit early to prevent further logic execution
      }
      let filelist = formDataList[index][field] || [];
      
      const uploadedFileId = response.data.organiserId.toString();
      filelist.push(uploadedFileId);
      setFormDataList((prevFormDataList) => {
        const updatedFormDataList = [...prevFormDataList];
        updatedFormDataList[index] = {
          ...updatedFormDataList[index],
          [field]: filelist,
        };
        return updatedFormDataList;
      });
      // setUploadfiles((prevUploadFiles) => [
      //   ...prevUploadFiles,
      //   response.data.organiserId,
      // ]);
      onSuccess("File uploaded");
    } catch (error) {
      onError("File upload failed");
    }
  };
  const handleFileUploadforpreview = async (field, options, index) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);
    let uploadedFileId;
    console.log("Before upload:", formDataList);
    try {
      // Clear any previous file for the given index and field
      setFormDataList((prevFormDataList) => {
        const updatedFormDataList = [...prevFormDataList];
        updatedFormDataList[index][field] = ""; // Clear previous file ID
        return updatedFormDataList;
      });
  
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/fileupload/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404 || !token) {
        navigate("/AdminLogin");
        return;
      } else {
        uploadedFileId = response.data.organiserId.toString();
  
        onSuccess("File uploaded");
      }
    } catch (error) {
      onError("File upload failed");
    }finally{
      if(uploadedFileId){
      console.log("Uploaded File ID:", uploadedFileId);
  
      setFormDataList((prevFormDataList) => {
        const updatedFormDataList = [...prevFormDataList];
        updatedFormDataList[index] = {
          ...updatedFormDataList[index],
          [field]: uploadedFileId,
        };
        console.log("After upload:", updatedFormDataList); // Log updated state
        return updatedFormDataList;
      });
    }
    }
  };
  const [formDataList, setFormDataList] = useState([]);
  
  useEffect(() => {
    if(formDataList){
    console.log(formDataList);
  }
  }, [formDataList]);
  
  useEffect(() => {
    if (!token) {
      return;
    }
    fetchSubData(selectedCategoryId, selectedSubCategoryId);
  }, [selectedCategoryId, selectedSubCategoryId]);

  const fetchSubData = async (categoryid, subcategoryid) => {
    try {
      // Construct the URL with query parameters
      const apiUrl = `http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory`;
      const requestBody = {
        categoryid: categoryid,
        subcategoryid: subcategoryid,
      };
      const response = await axios.put(apiUrl, requestBody, {
        headers: {
          //   'Authorization': 'Bearer ' + token,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setcategoryData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to open the edit modal
  const handleEditCategory = (product) => {
    setSelectedCategory(product);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };
  const handleSaveEdit = (editedData) => {
    // Handle the save logic here and update the categories array with the edited data
    // You can use the editedData to make an API call to save the changes
    console.log("Edited data:", editedData);
    // After saving, you can update the categories array and close the modal
    // For example, update the categories array and close the modal as follows:
    // setCategories((prevCategories) => {
    //   const updatedCategories = prevCategories.map((category) => {
    //     if (category.id === selectedCategory.id) {
    //       return { ...category, ...editedData };
    //     }
    //     return category;
    //   });
    //   return updatedCategories;
    // });
    handleCloseEditModal();
  };
  const resetFormFields = async () => {
    // setUploadfiles([]);
    setcategoryData([]);
    setProductname("");
    setSlug("");
    setSortdescription("");
    setFulldetails("");
    setColor("");
    setColorname("");
    setOfferprice("");
    setOfferstart("");
    setOfferend("");
    setUnit("");
    setQuantity("");
    setSellingprice("");
    setMrpprice("");
    setStorage("");
    setSize([]);
    setWeight("");
    setProductposition("");
    setSelectedOptions("");
    setProducttags("");
    setpreview("");
    setFileList([]); // Reset the file list for both preview and upload images
    setSelectedCategory(null);
    setSelectedSubCategoryId(null); // Reset the selected subcategory
    setSelectedSubSubCategoryId(null);

    try {
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory"
      );
      if (response.status === 401 || response.status === 404) {
        // Redirect or handle errors if needed
        console.error(
          "Error fetching category and subcategory data:",
          response.status
        );
        return;
      }
    } catch (error) {
      console.error("Error fetching category and subcategory data:", error);
    }
  };

  const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const token = localStorage.getItem("token");
    if (parseFloat(sellingprice) > parseFloat(mrpprice)) {
      // Display error message and return without making the API call
      console.error("Selling price cannot be greater than MRP price");
      return;
    }

    try {
      setLoading(true);
      // TODO: Replace with actual API call
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/product/add",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            {
            productname,
            slug,
            sortdescription,
            fulldetails,
            quantity:
              quantity !== null && quantity !== undefined && quantity !== ""
                ? quantity
                : "0",
            sellingprice,
            mrpprice,
            categoryid: selectedCategoryId,
            subcategoryid: selectedSubCategoryId,
            subsubcategoryid: selectedSubSubCategoryId,
            // uploadfile,
            storage,
            weight,
            productunit,
            options: selectedOptions,
            producttags,
            offerstart,
            offerend,
            offerprice,
            pricesymbol,
            countryoforigin,
            productbrandname: brandName,
            previeworganiserid: preview,
            producttypeid: selectedProductTypeId,
            ram,
            starrating,
            display,
            displayunit,
            os,
            capacity,
            type,
            author,
            capacityunit,
            productposition,
            youtube,
            material,
            agegroup: Agegroup,
            gender: Gender,
            packagecontent,
            packageweight,
            packagingunit,
            fragile,
            variations:formDataList,
          }
        ),
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        console.log("POST request successful");
        if (data && data.description) {
          notification.success({
            message: data.description,
            duration: 3, // Duration in seconds
          });
          navigate("/product-list");
          resetFormFields();
          // alert(data.description);
        }
        // setIsModalVisibleforagain(true);
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setData([]); // If no data is received, set an empty array
        }
        setLoading(false);
        // navigate("/p/roduct-list");
        if (data.token) {
          localStorage.setItem("token", token);
        }
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };
  const handleNameSelect = (categoryid) => {
    setSelectedSubCategoryId(null);
    setSelectedSubSubCategoryId(null);
    setSelectedCategoryId(categoryid);

    const subCategoryData = categorydata.find(
      (item) => item.categoryid === categoryid
    );

    setSubCategoriesList(subCategoryData.subcategory);

    if (subCategoryData.producttypeid) {
      getProductTypeDetails(subCategoryData.producttypeid);
    } else {
      // Reset fields if producttypeid is not available
      setGroupTypeFields([]);
    }
  };
  const getProductTypeDetails = async (producttypeid) => {
    const apiUrl = "http://68.178.169.226:12080/api/v1/producttype/getbyid";
    const requestBody = {
      producttypeid: producttypeid,
      // Add other required parameters if needed
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const productTypeDetails = await response.json();
        console.log("Product Type Details:", productTypeDetails);
        // Process the product type details as needed
        setGroupTypeFields(productTypeDetails.fields);
        await fetchSizeOptions(productTypeDetails.fields);
      } else {
        console.error("Failed to fetch product type details");
      }
    } catch (error) {
      console.error("Error fetching product type details:", error);
    }
  };
  const fetchSizeOptions = async (fields) => {
    try {
      // Check if 'Size' is included in the fields array
      if (fields.includes("Size")) {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/droupdown/getbydroupdown",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ droupdown: "size" }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSizeOptions(data);
          console.log("Size Dropdown Options:", data);
          // Process the dropdown options as needed
          return data; // Return the response data
        } else {
          console.error("Failed to fetch size dropdown options");
          return null; // Return null if the request fails
        }
      } else {
        console.log("Size field is not included");
        return null; // Return null if 'Size' field is not included
      }
    } catch (error) {
      console.error("Error fetching size dropdown options:", error);
      return null; // Return null in case of an error
    }
  };

  useEffect(() => {
    // Clear fields when component unmounts
    return () => setGroupTypeFields([]);
  }, []);

  // Call the function with the producttypeid of the selected category
  const handleNameSubCategory = (subcategoryid) => {
    setSelectedSubCategoryId(subcategoryid);
    const subsCategoryData = subcategoryList.find(
      (item) => item.subcategoryid === subcategoryid
    );
    if (subsCategoryData != null) {
      if(subsCategoryData.subsubcategory){
      setSubSubCategoriesList(subsCategoryData.subsubcategory);
      } else {
        setSubSubCategoriesList([]);
      }
    } else {
      setSubSubCategoriesList([]);
    }
    setSelectedSubSubCategoryId(null);
  };
  const handleNameSubSubCategory = (subsubcategoryid) => {
    setSelectedSubSubCategoryId(subsubcategoryid);
  };
  // Event handler to handle option selection
  // Event handler to handle option selection
  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };
  const handleOfferPriceChange = (value) => {
    setOfferprice(value);
    if (!sellingprice) {
      setIsOfferPriceValid(false);
      setOfferPriceErrorMessage("Please enter the selling price first.");
    } else if (parseFloat(value) >= parseFloat(sellingprice)) {
      setIsOfferPriceValid(false);
      setOfferPriceErrorMessage(
        "Offer price cannot be greater than selling price"
      );
    } else {
      setIsOfferPriceValid(true);
      setOfferPriceErrorMessage("");
    }
  };

  useEffect(() => {
    if (!token) {
      // Redirect to the login page if the token is not present
      navigate("/AdminLogin");
      return;
    }
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/producttype/getforadmin",
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate("/AdminLogin");
        return; // Exit early to prevent further logic execution
      }
      setGroupapitypedata(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleModalOk = () => {
    // Handle the "OK" button click in the modal
    // For example, reset the form fields and close the modal
    resetFormFields();
    setIsModalVisibleforagain(false);
  };

  const handleModalCancel = () => {
    // Handle the "Cancel" button click in the modal
    // For example, close the modal without resetting the form fields
    setIsModalVisibleforagain(false);
    navigate("/product-list");
  };


  useEffect(() => {
    // Initialize formDataList with the specified number of rows
    const initialFormDataList = Array.from(
      { length: dynamicformDatacount },
      () => ({
        Color: "",
        colorname: "",
        Size: "",
        Ram: "",
        Display: "",
        os: "",
        displayunit: "",
        capacityunit: "",
        storage: "",
        author: "",
        productunit: "",
        capacity: "",
        starrating: "",
        type: "",
        packagecontent: "",
        packageweight: "",
        packagingunit: "",
        fragile: false,
        Weight: "",
        sku: "",
        preview:"",
        uploadfile:[],
        prepreviewImageFileList:[],
        previewImageFileList:[],
        quantity:"",
        mrpprice:"",
        sellingprice:"",
        productposition:"",
        selectedOptions:[],
      })
    );
    setFormDataList(initialFormDataList);
  }, [dynamicformDatacount]);

  const handleAddRow = () => {
    setDynamicformDatacount(dynamicformDatacount + 1);
    setFormDataList((prevFormDataList) => [
      ...prevFormDataList,
      {
        Color: "",
        colorname: "",
        Size: "",
        Ram: "",
        Display: "",
        os: "",
        displayunit: "",
        capacityunit: "",
        storage: "",
        author: "",
        productunit: "",
        capacity: "",
        starrating: "",
        type: "",
        packagecontent: "",
        packageweight: "",
        packagingunit: "",
        fragile: false,
        Weight: "",
        sku: "",
        preview:"",
        uploadfile:[],
        prepreviewImageFileList:[],
        previewImageFileList:[],
        quantity:"",
        mrpprice:"",
        sellingprice:"",
        productposition:"",
        selectedOptions:[],
      },
    ]);
  };

  const handleRemoveRow = (indexToRemove) => {
    if (formDataList.length > 1) {
      setFormDataList((prevFormDataList) =>
        prevFormDataList.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const handleChange = (field, value, index) => {
    setFormDataList((prevFormDataList) => {
      const updatedFormDataList = [...prevFormDataList];
      updatedFormDataList[index] = {
        ...updatedFormDataList[index],
        [field]: value,
      };
      return updatedFormDataList;
    });
    console.log(formDataList);
  };

  const renderDynamicFields = () => {
    return formDataList.map((formData, index) => (
      <div key={index}>
        <h4 className="mt-3">Product {index +1} </h4>
        {groupTypeFields.map((field) => {
          let inputElement = null;
          let data = {};
          if (field === "Color") {
            return (
              <div className="row">
                <div className="col-md-6 mb-25">
                  <label className="form-label">Color</label>
                  <ColorPaletteModal
                    onSelectColor={(color) =>
                      handleChange("Color", color, index)
                    }
                  />
                  <div
                    className="selected-color-box"
                    style={{
                      backgroundColor: formDataList[index].Color,
                      width: "50px",
                      height: "50px",
                    }}
                  ></div>
                </div>
                <div className="col-md-6 mb-25">
                  <label className="form-label">Color Name</label>
                  <input
                    placeholder="Colour Name"
                    type="text"
                    className="form-control form-control-color"
                    value={formDataList[index].colorname}
                    onChange={(e) =>
                      handleChange("colorname", e.target.value, index)
                    }
                    required
                  />
                </div>
              </div>
            );
          }
          if (field === "Size") {
            data.Size = "";
            return (
              <div className="row">
                <div className="col-md-6 mb-25">
                  <label className="form-label">Size</label>
                  <select
                    className="form-select"
                    id="size"
                    value={formDataList[index].Size}
                    onChange={(e) =>
                      handleChange("Size", e, index)
                    }
                    style={{ backgroundColor: "#fff" }}
                  >
                    <option value="">Select Size</option>
                    {sizeOptions.map((option) => (
                      <option
                        key={option.droupdownid}
                        value={option.droupdownvalue}
                      >
                        {option.droupdownvalue}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            );
          }

          if (field === "Ram") {
            data.Ram = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">RAM</label>
                <input
                  type="text"
                  className="form-control"
                  id="weight"
                  value={formDataList[index].Ram}
                  onChange={(e) => handleChange('Ram', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Display") {
            data.Display = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Display Size</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].Display}
                  onChange={(e) => handleChange('Display', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "os") {
            data.os = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Operating System</label>
                <input
                  type="text"
                  className="form-control"
                  id="weight"
                  value={formDataList[index].os}
                  onChange={(e) => handleChange('os', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Displayunit") {
            data.Displayunit = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Display Unit</label>
                <input
                  type="text"
                  className="form-control"
                  id="weight"
                  value={formDataList[index].displayunit}
                  onChange={(e) => handleChange('displayunit', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "capacityunit") {
            data.capacityunit = "";
            <div className="col-md-6 mb-25">
              <label className="form-label">Capacity Unit</label>
              <input
                type="text"
                className="form-control"
                id="weight"
                value={formDataList[index].capacityunit}
                onChange={(e) => handleChange('capacityunit', e.target.value, index)}
                required
              />
            </div>;
          }
          if (field === "Storage") {
            data.Storage = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Storage</label>
                <input
                  type="text"
                  className="form-control"
                  id="weight"
                  value={formDataList[index].storage}
                  onChange={(e) => handleChange('storage', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Author") {
            data.Author = "";
            return (
              <div className="col-md-4 mb-25">
                <label className="form-label">Author</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].author}
                  onChange={(e) => handleChange('author', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Unit") {
            data.Unit = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Unit</label>
                <Select
                  style={{ width: "60%" }}
                  onChange={(e) => handleChange('productunit', e, index)}
                  value={formDataList[index].productunit}
                  required
                >
                  <Select.Option value="Kg">Kg</Select.Option>
                  <Select.Option value="G">g</Select.Option>
                  <Select.Option value="L">L</Select.Option>
                  <Select.Option value="ML">ml</Select.Option>
                </Select>
                {formDataList[index].productunit && (
                  <Button onClick={() => handleUnitSelect('productunit',"", index)}>Clear</Button>
                )}{" "}
              </div>
            );
          }
          if (field === "capacity") {
            data.capacity = "";
            return (
              <div className="col-md-4 mb-25">
                <label className="form-label">Capacity</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].capacity}
                  onChange={(e) => handleChange('capacity', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "starrating") {
            data.starrating = "";
            return (
              <div className="col-md-4 mb-25">
                <label className="form-label">Rating</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].starrating}
                  onChange={(e) => handleChange('starrating', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Type") {
            data.Type = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Type</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].type}
                  onChange={(e) => handleChange('type', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Packaging content") {
            data.Packaging_content = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Packaging content</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].packagecontent}
                  onChange={(e) => handleChange('packagecontent', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Packaging weight") {
            data.Packaging_weight = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Packaging weight</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].packageweight}
                  onChange={(e) => handleChange('packageweight', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "Packaging unit") {
            data.Packaging_unit = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Packaging unit</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].packagingunit}
                  onChange={(e) => handleChange('packagingunit', e.target.value, index)}
                  required
                />
              </div>
            );
          }
          if (field === "fragile") {
            data.fragile = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">BREAKABLE</label>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="Yes"
                      checked={formDataList[index].fragile === true}
                      onChange={() => handleChange('fragile', true, index)}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="No"
                      checked={formDataList[index].fragile === false}
                      onChange={() => handleChange('fragile', false, index)}
                    />
                    No
                  </label>
                </div>
              </div>
            );
          }
          if (field === "Weight") {
            data.Weight = "";
            return (
              <div className="col-md-6 mb-25">
                <label className="form-label">Weight</label>
                <input
                  type="text"
                  className="form-control form-control-color"
                  id="exampleColorInput1"
                  value={formDataList[index].weight}
                  onChange={(e) => handleChange('weight', true, index)}
                  required
                />
              </div>
            );
          }
          setDynamicformData(index, data);
        })}
        <div className="row">
          <div className="col-md-6 mb-25">
            <label className="form-label">Sku</label>
            <input
              placeholder="Sku"
              type="text"
              className="form-control form-control-color"
              value={formDataList[index].sku}
              onChange={(e) => handleChange("sku", e.target.value, index)}
              required
            />
          </div>
        </div>

        <div>
          <div className="col-md-12">
            <label className="form-label">Quantity</label>
            <input
              type="number"
              className="form-control"
              id="quantity1"
              value={formDataList[index].quantity}
              onChange={(e) => {
                // Check if the entered value is a valid positive number
                const value = parseInt(e.target.value, 10);
                if (!isNaN(value) && value >= 0) {
                  
                  handleChange("quantity", value.toString(), index);
                }
              }}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">MRP Price</label>
              <input
                type="number"
                className="form-control"
                id="price1"
                value={formDataList[index].mrpprice}
                onChange={(e) => handleChange("mrpprice", e.target.value, index)}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Selling Price</label>
              <input
                type="number"
                className="form-control"
                id="price2"
                value={formDataList[index].sellingprice}
                onChange={(e) => handleChange("sellingprice", e.target.value, index)}
              />
              {formDataList[index].mrpprice && formDataList[index].sellingprice && parseFloat(formDataList[index].sellingprice) > parseFloat(formDataList[index].mrpprice) && (
                <div className="error-message">Selling price cannot be greater than MRP price</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label">Product Position</label>
              <input
                type="number"
                className="form-control"
                value={formDataList[index].productposition}
                onChange={(e) => handleChange("productposition", parseInt(e.target.value, 10), index)}
              />
            </div>
            <div className="col-md-6 mb-25">
              <label className="form-label">Options</label>
              <Select
                mode="multiple" // Set mode to 'multiple' for multi-select
                style={{
                  width: "100%",
                  border: "2px solid #8f8f8f",
                }}
                placeholder="Select options"
                value={formDataList[index].selectedOptions}
                onChange={(selectedValues) => handleChange("selectedOptions", selectedValues, index)}
              >
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <h4 className="mt-3">PRODUCT IMAGES</h4>
            <div className="product-page col-md-12">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label" name="previewimage">
                      Thumbnail (Do not Repeat Main Image)
                    </label>
                    <Form.Item name="previewimage">
                      <ImgCrop grid rotate minZoom={0}>
                      <Upload
  customRequest={(options) => handleFileUploadforpreview("preview", options, index)}
  fileList={formDataList[index].prepreviewImageFileList || []}
  onChange={({ fileList }) => {
    const newFormDataList = [...formDataList];
    newFormDataList[index].prepreviewImageFileList = fileList.slice(-1); // Keep only the last uploaded file
    setFormDataList(newFormDataList);
  }}
  beforeUpload={() => {
    const newFormDataList = [...formDataList];
    newFormDataList[index].prepreviewImageFileList = []; // Clear the previous file
    setFormDataList(newFormDataList);
    return true;
  }}
  showUploadList={{ showRemoveIcon: true }}
  maxCount={1} // Limit to one file
>

                          <Button
                            icon={<UploadOutlined />}
                            style={{
                              top: "0px",
                              borderRadius: "0px",
                            }}
                          >
                            Click to Upload
                          </Button>
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                    {/* {formDataList[index].preview && formDataList[index].preview.length > 0 (<div>
                      upload images {formDataList[index].preview.length}
                    </div>)} */}
                  </div>

                  <div className="col-md-6">
                    <label className="form-label" name="uploadfile">
                      Product main Images
                    </label>
                    <Form.Item name="uploadfile">
                      <ImgCrop grid rotate minZoom={0}>
                        <Upload
                          customRequest={(options) => handleFileUpload("uploadfile", options, index)} // Use the customRequest prop for custom upload handling
                          fileList={formDataList[index].previewImageFileList || []}
                          onChange={({ fileList }) => {
                            const newFormDataList = [...formDataList];
                            newFormDataList[index].previewImageFileList = fileList;
                            setFormDataList(newFormDataList);
                          }}
                          // onChange={({ fileList }) => setFileList(fileList)}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{
                              top: "0px",
                              borderRadius: "0px",
                            }}
                          >
                            Click to Upload
                          </Button>
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                    
                    {/* {formDataList[index].uploadfile && formDataList[index].uploadfile.length > 0 (<div>
                       {formDataList[index].uploadfile.length}
                    </div>)} */}
                    {/* <Upload
														customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
														fileList={fileList}
														onChange={({ fileList }) => setFileList(fileList)}
														>
											<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
										</Upload> */}
                  </div>
                </div>
                {/* <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-label">
                                          Youtube Link
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={youtube}
                                          onChange={(e) =>
                                            setYoutube(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
        <Header />
        <div className="ec-content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Add Product</h1>
              </div>
              <div
                className="progress"
                role="progressbar"
                aria-label="Example with label"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  className="progress-bar bg-success"
                  style={{ width: "25%" }}
                >
                  25%
                </div>
              </div>

              <div>
                <Link to="/product-list" className="btn btn-primary">
                  {" "}
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="row card card-default">
                  <div
                    onClick={() => setStages(1)}
                    className="col-12"
                    style={{
                      backgroundColor: stages === 1 ? "#88aaf3" : "#ffff",
                    }}
                  >
                    <h4>Basic input</h4>
                    <h5 className="form-label">Category & product name</h5>
                  </div>
                  <div
                    onClick={() => setStages(2)}
                    className="col-12"
                    style={{
                      backgroundColor: stages === 2 ? "#88aaf3" : "#ffff",
                    }}
                  >
                    <h4>Key Features</h4>
                    <h5 className="form-label">product details</h5>
                  </div>
                  <div
                    onClick={() => setStages(3)}
                    className="col-12"
                    style={{
                      backgroundColor: stages === 3 ? "#88aaf3" : "#ffff",
                    }}
                  >
                    <h4>Variation</h4>
                    <h5 className="form-label">
                      Price, Stock, SKU, Availability ...
                    </h5>
                  </div>
                  <div
                    onClick={() => setStages(4)}
                    className="col-12"
                    style={{
                      backgroundColor: stages === 4 ? "#88aaf3" : "#ffff",
                    }}
                  >
                    <h4>Description</h4>
                    <h5 className="form-label">Details About the Product</h5>
                  </div>
                  <div
                    onClick={() => setStages(5)}
                    className="col-12"
                    style={{
                      backgroundColor: stages === 5 ? "#88aaf3" : "#ffff",
                    }}
                  >
                    <h4>Shipping & Warrenty</h4>
                    <h5 className="form-label">
                      Goods delivered to buyer with repair or replacement
                      assured for faults with a specific time.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col-12">
                    <div className="card card-default">
                      <div className="card-body">
                        <div className="row ec-vendor-uploads">
                          <div className="col-lg-12">
                            <div className="ec-vendor-upload-detail">
                              <form onSubmit={onFinish}>
                                <div className="row">
                                  {stages === 1 && (
                                    <div className="col-md-8">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <label className="form-label">
                                            Select Category
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            style={{ width: "60%" }}
                                            value={selectedCategoryId}
                                            onChange={(value) =>
                                              handleNameSelect(value)
                                            } // Pass a function reference
                                            placeholder="Select Category"
                                          >
                                            {categorydata.map((name) => (
                                              <Select.Option
                                                key={name.categoryid}
                                                value={name.categoryid}
                                              >
                                                {name.categoryname}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </div>
                                        
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select SubCategory
                                </label>
                                <Select
                                placeholder="Select Sub Category"
                                  style={{ width: "60%" }}
                                  onChange={(value) =>
                                    handleNameSubCategory(value)
                                  } // Pass a function reference
                                  value={selectedSubCategoryId}
                                >
                                  {subcategoryList.map((name) => (
                                    <Select.Option
                                      key={name.subcategoryid}
                                      value={name.subcategoryid}
                                    >
                                      {name.subcategoryname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                              <div className="col-md-4">
                                          <label className="form-label">
                                            Select SubsubCategory
                                          </label>
                                          <Select
                                          placeholder="Select Sub Category"
                                            style={{ width: "60%" }}
                                            onChange={(value) =>
                                              handleNameSubSubCategory(value)
                                            } // Pass a function reference
                                            value={selectedSubSubCategoryId}
                                          >
                                            {subsubcategoryList.map((name) => (
                                              <Select.Option
                                                key={name.subsubcategoryid}
                                                value={name.subsubcategoryid}
                                              >
                                                {name.subsubcategoryname}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {/* <h4 className="mt-3">BASIC INPUT</h4> */}

                                {groupTypeFields.length > 0 && stages === 3 && (
                                  <>
                                    <div className="row">
                                        <h4 className="mt-3 mb-3 col-md-6">Variations </h4>
                                      <div  className="mt-3 mb-3 btn col-md-6 bold" onClick={handleAddRow}>Add</div>
                                    </div>
                                    <div className="row product-page">
                                      <div className="col-md-8">
                                        <div className="row">
                                          {renderDynamicFields()}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="row  product-page">
                                  <div className="col-md-8">
                                    <div className="row">
                                      {/* <div className="row">
                                          <label
                                            for="inputEmail4"
                                            className="form-label"
                                          >
                                            Product Code
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control slug-title"
                                            id="inputEmailc"
                                            value={producttags}
                                            onChange={(e) =>
                                              setProducttags(e.target.value)
                                            }
                                          />
                                        </div> */}

                                      {stages === 1 && (
                                        <>
                                          {" "}
                                          <label
                                            for="inputEmail4"
                                            className="form-label"
                                          >
                                            Product Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="inputEmail4"
                                            value={productname}
                                            onChange={(e) =>
                                              setProductname(e.target.value)
                                            }
                                            autoComplete="off"
                                          />
                                        </>
                                      )}
                                      {stages === 2 && (
                                        <>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Country Of Origin
                                            </label>
                                            <input
                                              id="countryoforigin"
                                              name="countryoforigin"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={countryoforigin}
                                              onChange={(e) =>
                                                setcountryorigin(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Brand Name
                                            </label>
                                            <input
                                              id="brandName"
                                              name="brandName"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={brandName}
                                              onChange={(e) =>
                                                setBrandName(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Material
                                            </label>
                                            <input
                                              id="exampleColorInput1"
                                              name="exampleColorInput1"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={material}
                                              onChange={(e) =>
                                                setMaterial(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Age Group
                                            </label>
                                            <input
                                              id="agegroup"
                                              name="agegroup"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={Agegroup}
                                              onChange={(e) =>
                                                setAgegroup(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Gender
                                            </label>
                                            <input
                                              id="gender"
                                              name="gender"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={Gender}
                                              onChange={(e) =>
                                                setGender(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                        </>
                                      )}
                                      {/* {stages === 3 && (
                                        )} */}
                                      {stages === 5 && (
                                        <>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Country Of Origin
                                            </label>
                                            <input
                                              id="countryoforigin"
                                              name="countryoforigin"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={countryoforigin}
                                              onChange={(e) =>
                                                setcountryorigin(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Brand Name
                                            </label>
                                            <input
                                              id="brandName"
                                              name="brandName"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={brandName}
                                              onChange={(e) =>
                                                setBrandName(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Material
                                            </label>
                                            <input
                                              id="exampleColorInput1"
                                              name="exampleColorInput1"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={material}
                                              onChange={(e) =>
                                                setMaterial(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Age Group
                                            </label>
                                            <input
                                              id="agegroup"
                                              name="agegroup"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={Agegroup}
                                              onChange={(e) =>
                                                setAgegroup(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="form-label"
                                            >
                                              Gender
                                            </label>
                                            <input
                                              id="gender"
                                              name="gender"
                                              className="form-control here set-slug"
                                              type="text"
                                              value={Gender}
                                              onChange={(e) =>
                                                setGender(e.target.value)
                                              }
                                              autoComplete="off"
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {stages === 4 && (
                                  <>
                                    <h4 className="mt-3">
                                      PRODUCT DESCRIPTION
                                    </h4>
                                    <div className="row product-page">
                                      <div className="col-md-8">
                                        <CKEditor
                                          editor={ClassicEditor}
                                          data={fulldetails}
                                          onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log(
                                              "Editor is ready to use!",
                                              editor
                                            );
                                          }}
                                          onChange={(event, editor) => {
                                            const newSortDescription =
                                              editor.getData();
                                            setFulldetails(newSortDescription);
                                          }}
                                          onBlur={(event, editor) => {
                                            console.log("Blur.", editor);
                                          }}
                                          onFocus={(event, editor) => {
                                            console.log("Focus.", editor);
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <p>
                                          Selecting the right category ensures
                                          your product is easily discovered by
                                          customers, optimizing visibility and
                                          sales
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {/* <h4 className="mt-3">SEO</h4>
                                <div className="row product-page">
                                  <div className="col-md-8">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label
                                          for="slug"
                                          className="col-12 col-form-label"
                                        >
                                          Title(META TAG)
                                        </label>
                                        <div className="col-12">
                                          <input
                                            id="slug"
                                            name="slug"
                                            className="form-control here set-slug"
                                            type="text"
                                            value={slug}
                                            onChange={(e) =>
                                              setSlug(e.target.value)
                                            }
                                            autoComplete="off"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label className="form-label">
                                          DESCRIPTION (META TAG)
                                        </label>
                                        <textarea
                                          id="slug"
                                          name="slug"
                                          className="form-control here set-slug"
                                          type="text"
                                          value={sortdescription}
                                          onChange={(e) =>
                                            setSortdescription(e.target.value)
                                          }
                                          autoComplete="off"
                                          style={{
                                            resize: "none",
                                            backgroundColor: "#fff",
                                          }}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <p>
                                      Selecting the right category ensures your
                                      product is easily discovered by customers,
                                      optimizing visibility and sales
                                    </p>
                                  </div>
                                </div> */}

                                {/* {sellingprice && (
                                  <div>
                                    <h3 className="form-label">
                                      CountDown Sale
                                    </h3>
                                    <div className="row product-page">
                                      <div className="col-md-8">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="col-12 col-form-label"
                                            >
                                              Offer Price
                                            </label>
                                            <div className="col-12">
                                              <input
                                                id="slug"
                                                name="slug"
                                                autoComplete="off"
                                                className={`form-control here set-slug ${
                                                  !isOfferPriceValid
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                type="text"
                                                value={offerprice}
                                                onChange={(e) =>
                                                  handleOfferPriceChange(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              {!isOfferPriceValid && (
                                                <div className="invalid-feedback">
                                                  {offerPriceErrorMessage}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="col-12 col-form-label "
                                            >
                                              Offer Period Start
                                            </label>
                                            <div className="col-12 ">
                                              <DatePicker
                                                className=""
                                                style={{ border: "none" }}
                                                showTime={{ format: "hh:mm A" }}
                                                format="YYYY-MM-DD hh:mm A"
                                                placeholder="Select Date and Time"
                                                value={
                                                  offerstart
                                                    ? moment(
                                                        offerstart,
                                                        "YYYY-MM-DD hh:mm A"
                                                      )
                                                    : null
                                                }
                                                onChange={(date, dateString) =>
                                                  setOfferstart(dateString)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <label
                                              for="slug"
                                              className="col-12 col-form-label"
                                            >
                                              Offer Period End
                                            </label>
                                            <div className="col-12">
                                              <DatePicker
                                                className=""
                                                style={{ border: "none" }}
                                                showTime={{ format: "hh:mm A" }}
                                                format="YYYY-MM-DD hh:mm A"
                                                placeholder="Select Date and Time"
                                                value={
                                                  offerend
                                                    ? moment(
                                                        offerend,
                                                        "YYYY-MM-DD hh:mm A"
                                                      )
                                                    : null
                                                }
                                                onChange={(date, dateString) =>
                                                  setOfferend(dateString)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <p>
                                          Selecting the right category ensures
                                          your product is easily discovered by
                                          customers, optimizing visibility and
                                          sales
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                                {groupTypeFields.length > 0 && <div className="col-md-12">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                </div>}

                                <div
                                
                                  className="col-md-12 btn btn-primary"
                                  onClick={handleClick}
                                >
                                  Next
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Add Another Product?"
        open={isModalVisibleforagain}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Do you want to add another product?</p>
      </Modal>
    </div>
  );
};
export default ProductAdd;
