import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import { Button, Modal, DatePicker, notification, message } from 'antd';
import Header from '../Header';

const MilkProductAdd = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [pname, setPname] = useState('');
    const [qnty, setQnty] = useState('');
    const [price, setPrice] = useState('');
    const onFinish = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        try {
            setLoading(true);
            const response = await fetch(
                'http://localhost:8080/api/milkproducts/createProduct',
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({pname,qnty,price}),
                }
            );
            const data = await response.text();
            if (response.status === 401 || response.status === 404) {
                navigate('/login');
                return;
            }
            if (response.status == 201) {
                message.success('Milk-Product added successfully');
                navigate('/milkproduct-list');
            } else {
                message.error(data.description);
            }
        } catch (error) {
            console.error('Error posting data:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="wrapper">
            <SideBar />

            <div className="ec-page-wrapper">
                <Header />
                <div className="ec-content-wrapper">
                    <div className="content" >
                        <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                            <h1>Milk Product</h1>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <div className="ec-cat-list card card-default mb-24px">
                                    <div className="card-body">
                                        <div className="ec-cat-form">
                                            <h4>Add</h4>
                                            <form
                                                onSubmit={onFinish}
                                            >
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Product Name</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="text" value={pname} onChange={(e) => setPname(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Quantity</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="number" value={qnty} onChange={(e) => setQnty(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="text" className="col-12 col-form-label">Price</label>
                                                    <div className="col-12">
                                                        <input id="text" name="text" className="form-control here slug-title" type="number" value={price} onChange={(e) => setPrice(e.target.value)} autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button name="submit" type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MilkProductAdd;
