import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import _ from 'lodash';

const MilkProductEdit = ({ deliver, onClose }) => {
    const [form] = Form.useForm();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (deliver) {
            form.setFieldsValue(deliver);
        }
    }, [deliver, form]);

    const handleDeliverUpdate = async (values) => {
        const clonedFormData = _.cloneDeep({ ...values, productid: deliver.productid });
        try {
            const response = await axios.post(`http://localhost:8080/api/milkproducts/updateProduct`, clonedFormData, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });
            if (response.status === 200) {
                onClose();
                message.success('Deliver updated successfully');
            } else {
                console.error('Deliver update failed');
            }
        } catch (error) {
            console.error('Error updating Deliver:', error);
        }
    };

    return (
        <Form
            form={form}
            name="Milk Product Form"
            initialValues={deliver}
            onFinish={handleDeliverUpdate}
            layout='vertical'
        >
            <Form.Item
                name="pname"
                label="Product Name"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the product name!' }]}
            >
                <Input placeholder="Product Name" />
            </Form.Item>
            <Form.Item
                name="qnty"
                label="Quantity"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the quantity!' }]}
            >
                <Input placeholder="Quantity" />
            </Form.Item>
            <Form.Item
                name="price"
                label="Price"
                className="custom-form-item"
                rules={[{ required: true, message: 'Please input the price!' }]}
            >
                <Input placeholder="Price" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button onClick={onClose} style={{ marginLeft: '10px' }}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default MilkProductEdit;
