	import React, { useEffect, useState } from "react";
	import { DatePicker } from 'antd';
	import '../style.scss';
	import '../riot_admin.scss';
	import SideBar from '../sidebar';
	import Header from '../Header';
	import axios from "axios";
	import moment from 'moment';
	import {  Modal, Form, Input, Select } from 'antd';
	const { Option } = Select;
	const OrderDetail = (orderid) => {
		const token = localStorage.getItem('token');
		const [orderData, setOrderData] = useState(null);
		const [orderStatus, setOrderStatus] = useState("");
		const currentDate = moment();
		const [cancelReasonModalVisible, setCancelReasonModalVisible] = useState(false);
		const mapOrderStatusToStep = (orderStatus) => {
			switch (orderStatus) {
				case "Placed":
				return "Placed";
			  case "OrderConfirmed":
				return "Confirmed Order";
			  case "processingorder":
				return "Processing Order";
			  case "productdispatched":
				return "Product Dispatched";
			  case "OutForDelivery":
				return "On Delivery";
			  case "Delivered":
				return "Product Delivered";
			  default:
				return "";
			}
		  };
		  const [trackingSteps, setTrackingSteps] = useState([]);
		  const updateTrackingSteps = (orderStatus) => {
			const steps = [
			  { title: "Confirmed Order", icon: "cart", completed: false },
			  { title: "Processing Order", icon: "tumblr-reblog", completed: false },
			  { title: "Product Dispatched", icon: "gift", completed: false },
			  { title: "On Delivery", icon: "truck-delivery", completed: false },
			  { title: "Product Delivered", icon: "hail", completed: false },
			];
			const mappedSteps = steps.map((step) => {
				if (step.title === mapOrderStatusToStep(orderStatus)) {
				  return { ...step, completed: true };
				} else {
				  return { ...step, completed: false };
				}
			  });
		  
			  setTrackingSteps(mappedSteps);
			};
		  
			useEffect(() => {
			  updateTrackingSteps(orderData?.orderStatus || "");
			}, [orderData?.orderStatus]);
		  
		const getOrderDetails = async () => {
			const selectedID = localStorage.getItem("selectedID");
			try{
			const payload = {
				orderid:selectedID,
			}
			const response = await axios.post("http://68.178.169.226:12080/api/v1/order/getbyid",payload,{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if(response.status === 200){
				const dataOrder = response.data;
				setOrderData(dataOrder);
			}
			else{
				console.error("Failed to fetch order details");
			}
		} catch(error) {
			console.error("Error fetching Order details:", error);
		}
		};
		useEffect(() => {
			getOrderDetails();
		}, []);
		const handleStatusChange = async (newStatus, reason = null) => {
			// Show a confirmation popup using window.alert
			const confirmed = window.confirm(`Are you sure you want to change the order status to ${newStatus}?`);
			try {
			  const payload = {
				orderid: orderData.orderid,
				orderStatus: newStatus,
				admincancellreason: reason,
			  };
		  
			  if (confirmed) {
				const response = await axios.post(
				  "http://68.178.169.226:12080/api/v1/order/editstatus",
				  payload,
				  {
					headers: {
					  Authorization: `Bearer ${token}`,
					},
				  }
				);
		  
				if (response.status === 200) {
				  console.log("Order status updated successfully");
				  // You might want to update the orderData state with the new data received from the server
				  // For example, call getOrderDetails again after updating the status
				  getOrderDetails();
				} else {
				  console.error("Failed to update order status");
				}
			  } else {
				// If the user cancels the confirmation, you can handle it accordingly
				console.log("Order status change cancelled");
			  }
			} catch (error) {
			  console.error("Error updating Order status:", error);
			}
		  };
		  
		
		useEffect(() => {
			getOrderDetails();
		}, []);
		const handleCancelReasonSubmit = (reason) => {
			handleStatusChange("Cancelled", reason);
			setCancelReasonModalVisible(false);
		};
	return(
	<div className="wrapper">
	<SideBar/>
		<div className="ec-page-wrapper">
	<Header/>
	<div className="ec-content-wrapper">
					<div className="content">
						<div className="breadcrumb-wrapper breadcrumb-wrapper-2">
							<h1>Order Detail</h1>
							<p className="breadcrumbs"><span><a href="index.html">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Orders
							</p>
						</div>
						{orderData ? (
						<div className="row">
							<div className="card mt-4 trk-order">
									<div className="p-4 text-center text-white text-lg bg-dark rounded-top">
										<span className="text-uppercase">Tracking Order No - </span>
										<span className="text-medium">{orderData.ordernumber}</span>
									</div>
									<div
										className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
										<div className="w-100 text-center py-1 px-2"><span className="text-medium">Shipped
												Via:</span> UPS Ground</div>
										<div className="w-100 text-center py-1 px-2"><span className="text-medium">Status:</span>
											{orderData.orderStatus}</div>
										<div className="w-100 text-center py-1 px-2"><span className="text-medium">Expected
												Date:</span>  <DatePicker defaultValue={currentDate} /></div>
									</div>
									<div className="card-body">
										<div
											className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
											{trackingSteps.map((step) => (
                <div key={step.title} className={`step ${step.completed ? 'completed' : ''}`}>
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      <i className={`mdi mdi-${step.icon}`}></i>
                    </div>
                  </div>
                  <h4 className="step-title">{step.title}</h4>
                </div>
              ))}
										</div>
									</div>
								</div>
							<div className="col-12">
								<div className="ec-odr-dtl card card-default">
									<div className="card-header card-header-border-bottom d-flex ">
										<h2 className="ec-odr">Order Detail<br/>
											<span className="small justify-content-right col-7">Order ID: #{orderData.orderid}</span>
										
											<Select
                            id="statusDropdown"
                            value={orderData.orderStatus}
                            onChange={(value) => {
                                if (value === "Cancelled") {
                                    setCancelReasonModalVisible(true);
                                } else {
                                    handleStatusChange(value);
                                }
                            }}
                        >
                            <Option value="Placed">Placed</Option>
                            <Option value="OrderConfirmed">Order Confirmed</Option>
                            <Option value="Cancelled">Cancelled</Option>
                            <Option value="processingorder">Processing Order</Option>
                            <Option value="productdispatched">Product Dispatched</Option>
                            <Option value="OutForDelivery">Out For Delivery</Option>
                            <Option value="Delivered">Delivered</Option>
                            <Option value="Returned">Returned</Option>
                        </Select>
                        <Modal
                            open={cancelReasonModalVisible}
                            title="Enter Cancellation Reason"
                            onCancel={() => setCancelReasonModalVisible(false)}
                            footer={null}
                        >
                            <Form onFinish={(values) => handleCancelReasonSubmit(values.reason)}>
                                <Form.Item
                                    name="reason"
                                    label="Reason"
                                >
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                <Form.Item>
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                </Form.Item>
                            </Form>
                        </Modal>
										</h2>
									</div>
									
									<div className="card-body">
										<div className="row">
											<div className="col-xl-3 col-lg-6">
												<address className="info-grid">
													<div className="info-title"><strong>Billing Address:</strong></div><br/>
													<div className="info-content">
													{orderData.billingaddress}
													</div>
												</address>
											</div>
											<div className="col-xl-3 col-lg-6">
												<address className="info-grid">
													<div className="info-title"><strong>Shipped Address:</strong></div><br/>
													<div className="info-content">
													{orderData.shippingaddress.name}<br/>
													{orderData.shippingaddress.address1},{orderData.shippingaddress.address2}<br/>
													{orderData.shippingaddress.city},{orderData.shippingaddress.state},{orderData.shippingaddress.country}<br/>
													{orderData.shippingaddress.pincode}<br/>
													{orderData.shippingaddress.phonenumber}
													</div>
												</address>
											</div>
											<div className="col-xl-3 col-lg-6">
												<address className="info-grid">
													<div className="info-title"><strong>Payment Method:</strong></div><br/>
													<div className="info-content">
														{orderData.paymentmethod==="cod"? "Cash On Delivery":orderData.paymentmethod}
													</div>
												</address>
											</div>
											<div className="col-xl-3 col-lg-6">
  <address className="info-grid">
    <div className="info-title"><strong>Order Date:</strong></div><br />
    <div className="info-content">
      {orderData.createDate && (
        <div>
          <p>{new Date(orderData.createDate).toLocaleDateString()}</p>
          <p>{new Date(orderData.createDate).toLocaleTimeString()}</p>
        </div>
      )}
    </div>
  </address>
</div>

										</div>
										<div className="row">
											<div className="col-md-12">
												<h3 className="tbl-title">PRODUCT SUMMARY</h3>
												<div className="table-responsive">
													<table className="table table-striped o-tbl">
														<thead>
															<tr className="line">
																<td><strong>#</strong></td>
																<td className="text-center"><strong>IMAGE</strong></td>
																<td className="text-center"><strong>PRODUCT</strong></td>
																<td className="text-center"><strong>PRICE/UNIT</strong></td>
																<td className="text-right"><strong>QUANTITY</strong></td>
																<td className="text-right"><strong>SUBTOTAL</strong></td>
															</tr>
														</thead>
														<tbody>
														{orderData.products.map((product, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td><img className="product-img"
																		src={product.productimage} alt="" /></td>
																<td><strong>{product.productname}</strong><br/>
																<td dangerouslySetInnerHTML={{ __html: product.sortdescription }}></td></td>
																<td className="text-center">{product.sellingprice}</td>
																<td className="text-center">{product.productcount}</td>
																<td className="text-right">{orderData.subtotal}</td>
															</tr>
														))}
															{/* <tr>
																<td colspan="4"></td>
																<td className="text-right"><strong>Taxes</strong></td>
																<td className="text-right"><strong>N/A</strong></td>
															</tr> */}
															<tr>
																<td colspan="4">
																</td>
																<td className="text-right"><strong>Total</strong></td>
																<td className="text-right"><strong>{orderData.total}</strong></td>
															</tr>

															<tr>
																<td colspan="4">
																</td>
																<td className="text-right"><strong>Payment Status</strong></td>
																<td className="text-right"><strong>{orderData.paymentstatus}</strong></td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						) : (
							// Render a loading state or handle the absence of data
							<p>Loading...</p>
						)}
					</div>
				</div> 
</div>
	</div>
										
		);
	}
	export default OrderDetail;